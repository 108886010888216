.t{
    width: 50px;
    height: 25px;
    border-radius: 20px;
    bottom: 1pz solid gray;
    background-color: white;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.t-icon
{
    width: 15px;
    height: 15px;
}

.t-button
{
    width: 100px;
    height: 100px;
    /* background-color: yellow; */
    border: solid purple;
    /* position: absolute; */
    cursor: pointer;
    /* typing "left:25" here makes the button look like it's on one side of the switch. BUT we want it to slide based on a condition, so we will write a function in the .jsx file. */
    /* background-image: url("../../svg/Night.svg"); */
    /* background-image: none; */
    /* background-size:cover; */
    /* background-position:100%; */
    background-image: url("../../svg/Night.svg");
    /* width: 100%;
    height: 100%; */
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.t-navbar-icon
{
    width: 105px;
    height: 105px;
}