.f
{
    background-color: #F3F3F3;
}

/* Holds the entire grid */
.f-grid-container 
{
    display: grid;
    grid-template-columns: auto auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
}

/* Holds the entire grid of one row */
.f-grid-container-single-row {
    display: grid;
    grid-template-rows: auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.f-grid-item-col 
{
/* Makes this grid-item into a flex container */
display: flex;
/* Center items horizontally */
justify-content: center;
/* Center items vertically */
align-items: center;
/* You can set direction as column or row depending on what you're putting into the flex container */
flex-direction: column;

/* Styling */
/* background-color: rgba(255, 255, 255, 0.8); */
/* border: 1px solid rgba(0, 0, 0, 0.8); */
padding: 10px;
font-size: 30px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in rows */
.f-grid-item-row 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: space-between;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 0px;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 30px;
}

.f-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333; */
    /* background-color: #dfdfdf; */
    
}

.f-logo-links
{
    justify-content: space-evenly;
}

.f-copyright-note
{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    /* identical to box heigßht, or 17px */
    letter-spacing: -0.03em;
    display: inline;
}


.f-email-logo
{
    width: 36px;
    height: 36px;
    position: relative;
    top: 6px;
    fill: #2196F3;
    margin-right: 10px;
    background-image: none;
    padding: 4px;
}

.f-email-logo:hover
{
    background-image: url("./svg/nav-circle3.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: show;
}

.f-linkedin-logo
{
    width: 24px;
    height: 24px;
    fill: #FF5E5B;
    margin-left: 10px;
    margin-right: 10px;
    background-image: none;
    padding: 4px;
    position: relative;
}

.f-linkedin-logo:hover
{
    background-image: url("./svg/nav-circle6.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: show;
}

.f-github-logo
{
    width: 30px;
    height: 30px;
    fill: #2196F3;
    margin-left: 10px;
    position: relative;
    padding: 4px;
    padding-bottom: 0px;
    background-image: none;
}

.f-github-logo:hover
{
    background-image: url("./svg/nav-circle5.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: show;
}

.copyright-symbol
{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    /* identical to box height, or 17px */
    letter-spacing: -0.03em;
    display: inline;
}

@media screen and (min-width: 300px)
{
    .f-copyright-note
    {
        font-size: 10px;
    }

    .copyright-symbol
    {
        font-size: 10px;
    }

    .f-grid-container-single-row 
    {
        padding: 0px;
    }
}

@media screen and (min-width: 768px)
{
    .f-copyright-note
    {
        font-size: 14px;
    }

    .copyright-symbol
    {
        font-size: 14px;
    }

    .f-grid-container-single-row 
    {
        padding: 0px;
    }
}

@media screen and (min-width: 1200px)
{
    .f-copyright-note
    {
        font-size: 16px;
    }

    .copyright-symbol
    {
        font-size: 16px;
    }

    .f-grid-container-single-row 
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }
}