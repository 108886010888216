.card
{
    width: 100%;
    height: 400px;
    font-size: 30px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(255, 255, 255); */
}

.card-content
{
    width: auto;
    height: 400px;
    object-fit: contain;
}

/* Media Queries */
@media screen and (min-width: 300px)
{
    .card
    {
        height: 200px;
    }

    .card-content
    {
        height: 200px;
    }
}

@media screen and (min-width: 768px)
{
    .card
    {
        height: 300px;
    }

    .card-content
    {
        height: 300px;
    }
}

@media screen and (min-width: 1200px)
{
    .card
    {
        height: 400px;
    }

    .card-content
    {
        height: 400px;
    }
}