.c
{
    background-color: #F3F3F3;
}

/* Holds the entire grid */
.c-grid-container 
{
    display: grid;
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
}

/* Holds the entire grid */
.c-grid-container-three-cols 
{
    display: grid;
    /* grid-template-columns: auto auto auto; */
    grid-template-columns: repeat(3, minmax(0, 1fr));
    /* background-color: #2196F3; */
    padding: 0px;
    padding-left: 72px;
    padding-right: 72px;
}

/* Holds the entire grid of one row */
.c-grid-container-single-row 
{
    display: grid;
    grid-template-rows: auto;
    /* Shorthand method for evenly-spacing the 5 columns */
    /* grid-template-columns: repeat(5, minmax(0, 1fr)); */
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
  .c-grid-item-col 
  {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
  .c-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  .c-grid-item-col-left-align {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: flex-start;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  .c-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333; */
    /* background-color: #dfdfdf; */

    /* Font styling */
    font-style: normal;
    font-size: 16px;
}

.c-title
{

    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    color: #181717;
    display: flex;
}

.c-subtitle
{
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 54px;
    display: flex;
    align-items: center;
    color: #181717;
}

.c-info-item
{
    display: flex;
    align-items: center;
    /* top, bottom */
    margin: 30px 0px;
    font-weight: 300;
    /* Makes sure the address line doesn't run over to the middle of the page. It wraps over instead. */
    width: 70%;
}

form
{
    padding: 0px;
}

input
{
    height: 50px;
    border: none;
    /* border-bottom: 1px solid black; */
    /* top, bottom */
    margin: 10px;
    /* padding: 10px; */
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    /* border-image: url("../../svg/FormLine1.svg"); */
    /* border-width: 15px; */
    /* Font styling */
    font-style: normal;
    font-size: 18px;
}

textarea
{
    /* top, bottom */
    margin: 10px;
    /* padding: 10px; */
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    border: none;
    /* Font styling */
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-size: 18px;
    /* min-width: 50vh;
    min-height: 22vh; */
}

button
{
    border: none;
    padding: 15px;
    margin: 15px;
    /* Font styling */
    font-family: 'Assistant', sans-serif;
    /* font */
    /* font-weight: 100px; */
    /* font-family: 'Rock Salt'; */
    font-style: normal;
    font-size: 16px;
    /* Main button color */
    background-color: #FF5E5B;
    /* background-color:rgba(0, 0, 0, 0); */
    /* Text color */
    color: white;
    /* color: #FF5E5B; */
    /* font-weight: 500; */
    /* text-decoration: underline; */
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    background-image: none;
    transition: all .2s ease-in-out;
    font-weight: 200;
}

button:hover
{
    /* background-image: url("../../svg/Submit-Line.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: show; */
    transform: scale(1.05); 
}

.c-name-input
{
    background-image: url("../../svg/FormLine1.svg");
    background-size: 100% 100%;
    background-position: 0px 25px;
    background-repeat: no-repeat;
}

.c-subject-input
{
    background-image: url("../../svg/FormLine2.svg");
    background-size: 100% 100%;
    background-position: 0px 25px;
    background-repeat: no-repeat;
}

.c-email-input
{
    background-image: url("../../svg/FormLine3.svg");
    background-size: 100% 100%;
    background-position: 0px 25px;
    background-repeat: no-repeat;
}

.c-message-textarea
{
    /* background-image: url("../../svg/box-outline1.svg"), url("../../svg/box-outline2.svg"), url("../../svg/box-outline3.svg"), url("../../svg/box-outline4.svg");
    background-position: right bottom, left bottom, left top, right top;
    background-size: contain;
    background-repeat: no-repeat; */
    border: solid black;
    border-width: 1px;
    padding: 15px;
    min-width: 593px;
    min-height: 263px;
    max-width: 800px;
    max-height: 400px;
}

.c-bottom-note-text
{
    font-family: "Assistant";
    font-style: normal;
    font-size: 22px;
    font-weight: 100;
    text-align: center;
}

.c-sketchy-arrow-flexbox
{
    /* position: absolute; */
    /* top: 70px;
    left: 100px; */
    /* position: relative;
    top: 70px;
    left: 100px; */
    position: relative;
    top: 70px;
    right: 0px;
    /* background-color: rgba(0, 0, 0, 0); */
    border: none;
    width: 100%;
    height: 100%;
}

.c-bottom-note-cell
{
    justify-content: center;
    padding: 0px;
}

.contact-grid-container
{
    padding: 0px;
}

/* Responsive design commands below */
/* When you open this site on a mobile device, this media query will be called. */
/* It is highly recommended that for mobile, you delete all long text. Only keep what can be used immediately. */
/* Recommended max-width for mobile is 480px and for tablets, it is 768px. For laptops and small screens, it is 1200px. */

@media screen and (min-width: 300px)
{
    .c-message-textarea
    {
        min-width: 193px;
        /* min-height: 263px; */
        max-width: 400px;
        /* max-height: 400px; */
    }

    .c-grid-container
    {
        grid-template-columns: auto;
    }

    input
    {
        min-width: 240px;
        max-width: 600px;
    }

    /* .c-sketchy-arrow-flexbox
    {
        position: relative;
        top: 70px;
        right: 60px;
        background-color: rgba(0, 0, 0, 0);
        border: solid red;
        z-index: 4000000;
        scale: 80%;
        left: 0px;
    } */

    .c-grid-container-three-cols
    {
        grid-template-columns: auto auto auto;
        padding: 0px;
    }

    .c-grid-container-single-row
    {
        padding: 0px;
    }

    .contact-grid-container
    {
        padding: 0px;
    }
}

@media screen and (min-width: 768px)
{
    .c-message-textarea
    {
        min-width: 393px;
        /* min-height: 263px; */
        max-width: 600px;
        /* max-height: 400px; */
    }

    .c-grid-container
    {
        grid-template-columns: auto;
    }

    input
    {
        min-width: 240px;
        max-width: 600px;
    }

    .c-grid-container-three-cols 
    {
    
        grid-template-columns: auto auto auto;
        padding: 0px;
    }

    .c-grid-container
    {
        padding: 0px;
    }

    .c-grid-container-single-row
    {
        padding: 0px;
    }

    .contact-grid-container
    {
        padding: 0px;
    }
}

@media screen and (min-width: 1200px)
{
    .c-message-textarea
    {
        /* background-image: url("../../svg/box-outline1.svg"), url("../../svg/box-outline2.svg"), url("../../svg/box-outline3.svg"), url("../../svg/box-outline4.svg");
        background-position: right bottom, left bottom, left top, right top;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 15px; */
        min-width: 593px;
        /* min-height: 263px; */
        max-width: 700px;
        /* max-height: 400px; */
    }

    .c-grid-container
    {
        grid-template-columns: auto auto;
    }

    input
    {
        min-width: 240px;
        max-width: 600px;
    }

    .c-grid-container-three-cols 
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }

    .c-grid-container
    {
        padding: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }

    .c-grid-container-single-row
    {
        padding: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }

    .contact-grid-container
    {
        padding: 0px;
    }
}