.i
{
    scroll-behavior: smooth;
    background-color: #F3F3F3;
}

/* Holds the entire grid */
.i-grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* Holds the entire grid of one row */
.i-grid-container-single-row {
    display: grid;
    grid-template-columns: auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.i-grid-item-col 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center; 

    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 50px;
    font-size: 30px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in rows */
.i-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center; 
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
}

.i-grid-item-space {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    height: 200px;
  }

.i-flexbox-item
{
    /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 0px;
    /* border: 3px solid #333; */
    /* background-color: #dfdfdf; */
}

.i-intro
{
    border: none;
    /* Keeps text in center of box. (Center aligned text)*/
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 90px;
    padding-bottom: 30px;
}

.i-name
{
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    text-align: center;
}

/* .name-flexbox
{
    background-image: url("../../svg/HomeScribble.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 40px;
    padding-bottom: 15px;
} */

.i-home-scribble
{
    border: none;
    width: 392.59px;
    height: 19.42px;
    left: 522.2px;
    top: 499.79px;

}

.i-title-item
{
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #181717;
    width: 50vw;
}
.i-and-text
{
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;

    display: inline;
    text-align: center;
    color: #181717;

}

.i-des-text
{
    border: none;
    font-family: 'Rock Salt';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    /* text-decoration: underline; */

    display: inline;
    text-align: center;
    color: #181717;
}

.i-dev-text
{
    border: none;
    /* font-family: 'JetBrains Mono'; */
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    /* text-decoration: underline; */

    display: inline;
    text-align: center;
    color: #181717;
}

.i-home-dots
{
    /* border: none;
    width: 45px;
    height: 15px; */
    /* stroke: black; */
    font-family: 'Rock Salt';
    font-size: 40px;
    letter-spacing: 3px;
}

.i-page-break
{
    width: 40em;
    /* width: 1439px; */
    height: 5px;
}

@media screen and (min-width: 300px)
{
    .i-intro
    {
        font-size: 20px;
    }

    .i-name
    {
        font-size: 50px;
        /* background-image: none; */
    }

    /* .name-flexbox
    {
        background-image: none;
    } */

    .i-home-scribble
    {
        display: none;
    }

    .i-title-item
    {
        font-size: 26px;
    }

    .i-dev-text
    {
        font-size: 26px;
    }

    .i-and-text
    {
        font-size: 26px;
    }

    .i-des-text
    {
        font-size: 20px;
    }

    .i-page-break
    {
        display: inline;

    }

    .i-grid-item-col
    {
        padding-left: 10px;
        padding-right: 10px;
    }

    .i-page-break
    {
        width: 40em;
        height: 5px;
    }

    .i-grid-container 
    {
        padding: 0px;
    }

    .i-grid-container-single-row
    {
        padding: 0px;
    }
}

@media screen and (min-width: 768px)
{
    .i-intro
    {
        font-size: 30px;
    }

    .i-name
    {
        font-size: 60px;
        /* background-image: none; */
    }

    /* .name-flexbox
    {
        background-image: url("../../svg/HomeScribble.svg");
    } */

    .i-home-scribble
    {
        display: inline;
    }

    .i-title-item
    {
        font-size: 36px;
    }

    .i-dev-text
    {
        font-size: 36px;
    }

    .i-and-text
    {
        font-size: 36px;
    }

    .i-des-text
    {
        font-size: 30px;
    }

    .i-page-break
    {
        width: 40em;
        height: 5px;
    }

    .i-grid-container 
    {
        padding: 0px;
    }

    .i-grid-container-single-row 
    {
        padding: 0px;
    }
}

@media screen and (min-width: 1200px)
{
    .i-intro
    {
        font-size:30px;
    }

    /* .name-flexbox
    {
        background-image: url("../../svg/HomeScribble.svg");
    } */

    .i-name
    {
        font-size: 60px;
        /* background-image: none; */
    }

    .i-title-item
    {
        font-size: 36px;
    }

    .i-dev-text
    {
        font-size: 36px;
    }

    .i-and-text
    {
        font-size: 36px;
    }

    .i-des-text
    {
        font-size: 30px;
    }

    .i-page-break
    {
        width: 40em;
        height: 5px;
    }

    .i-grid-container 
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }

    .i-grid-container-single-row 
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }
}