/* Holds the entire grid */
.pp-grid-container 
{
    display: grid;
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

  /* Holds the entire grid of one row */
  .pp-grid-container-double-col
  {
      display: grid;
      grid-template-columns: auto auto;
      /* background-color: #2196F3; */
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 72px;
      padding-right: 72px;
      align-items: flex-start;
  }
  

  .pp-concept-grid-container
  {
    padding-top:0px;
    padding-bottom:0px;
  }

  /* Holds the entire grid of one row */
.pp-grid-container-single-row 
{
    display: grid;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

.pp-grid-container-five-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(5, minmax(0, 1fr));
    /* background-color: #2196F3; */
    /* padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px; */
    /* width: 75%; */
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    width: 100%;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
  .pp-grid-item-col {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  .pp-grid-item-col-left-align {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border-left: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  .pp-grid-item-col-right-align {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-end;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;
    text-align: right;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border-right: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
  .pp-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  .pp-grid-item-row-left-align {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: flex-start;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    padding-left: 5%;
  }

  .pp-grid-item-row-right-align {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: flex-end;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    padding-left: 5%;
  }

  .pp-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;
    justify-content: center;
    align-items: center;
    /* Styling */
    margin: 10px;
    /* border: 3px solid #333; */
    /* background-color: #dfdfdf; */
    font-size: 24px;
}

.pp-grid-item-space {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    height: 100px;
  }

.pp-title
{
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 72px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* Main Black */
    color: #181717;
}

.pp-subtitle
{
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Main Black */

    color: #181717;
}

.subtitle-flexbox
{
    margin-left: 72px;
    /* padding-left: 72px; */
    /* padding-right: 72px; */
    font-size: 24px;
}

.goals-list
{
    font-style: normal;
    font-size: 20px;
}

.sketchy-box-text-flexbox
{
  
    /* background-color: blue; */
    background-repeat: no-repeat;
    background-position:top right;
    white-space: nowrap;
    background-size: 100% 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    /* line-height: 27px; */
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.pp-role-text
{
    font-size: 24px;
    text-align: center;
    padding: 10px;
    width: 200px;
}

.sb1
{
    background-image: url("../../svg/SketchyButton1.svg");
}
.sb2
{
    background-image: url("../../svg/SketchyButton2.svg");
}
.sb3
{
    background-image: url("../../svg/SketchyButton4.svg");
}
.sb5
{
    background-image: url("../../svg/SketchyButton5.svg");
}
.sb6
{
    background-image: url("../../svg/SketchyButton6.svg");
}
.sb7
{
    background-image: url("../../svg/SketchyButton7.svg");
}
.sb8
{
    background-image: url("../../svg/SketchyButton8.svg");
}

.sb9
{
    background-image: url("../../svg/SketchyButton9.svg");
}

.sb10
{
    background-image: url("../../svg/SketchyButton10.svg");
}

.sb11
{
    background-image: url("../../svg/SketchyButton11.svg");
}

.sb12
{
    background-image: url("../../svg/SketchyButton12.svg");
}

.sb13
{
    background-image: url("../../svg/SketchyButton13.svg");
}

.sb14
{
    background-image: url("../../svg/SketchyButton14.svg");
}

.sb15
{
    background-image: url("../../svg/SketchyButton15.svg");
}

.sb16
{
    background-image: url("../../svg/SketchyButton16.svg");
}

.pp-main-vid
{
    /* width: 1112px;
    height: 628px; */
    /* border-radius: 12px; */
    width: 80vw;
    height: 50vh;
    align-self: center;
    justify-self: center;
}

.pp-main-desc
{
    width: 1120px;
    /* height: 90px; */

    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    /* or 167% */

    display: flex;
    align-items: center;

    /* Main Black */

    color: #181717;
}

.pp-visit-site
{
    font-family: "Rock Salt";
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    text-align: center;
    text-decoration-line: none;

    color: #FF5E5B;
    
}

.pp-visit-site-arrow
{
    display: inline;
}

.pp-visit-site-flexbox
{
    background-image: url("../../svg/link-underline.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 15px;
    transition: all .2s ease-in-out;
}

.pp-visit-site-flexbox:hover
{
    transform: scale(1.05); 
}

.pp-concept-img
{
    /* width: 1280px; */
    width: 1120px;
    height: 552px;
    object-fit: cover;
    /* object-position: 0px - 50px; */
}
.vr-concept-img
{
    object-position: 0px - 50px;
}

.rhino-concept-img
{
    object-position: 0px -130px;
}

.pp-goals-vid
{
    /* width: 1280px; */
    width: 1280px;
    /* height: 720px; */
    /* object-fit: cover; */
}

.pp-detail-component-caption
{
    font-style: normal;
    font-size: 30px;
}

.pp-goals-vid:hover
{
    cursor: pointer;
}

#goals-vid-flexbox:hover~#overlay-flexbox
{
    display: block;
}

#overlay-flexbox:hover
{
    display: block;
}

/* .video-overlay:hover
{
    display: block;
} */

/* .vid-overlay-box:hover
{
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
} */

.vid-overlay-text
{
    font-family: "Rock Salt";
    color: black;
}

.vid-overlay-box
{
    width: 1286px;
    height: 360px;
    background-color: white;
    opacity: 0.75;
}

.video-overlay
{
    position:absolute;
    background: rgba(0, 0, 0, 0);
    border: none;
    display: none;
    text-align: center;
    pointer-events: none;
}

video
{
    cursor: pointer;
}

.pp-back-link
{
    height: 43px;

    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 43px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-decoration-line: none;
    color: #FF5E5B;

    background-image: url("../../svg/link-underline.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 15px;
    transition: all .2s ease-in-out;
}

.pp-back-link-flexbox:hover
{
    transition: all .2s ease-in-out;
}

.pp-back-link-flexbox:hover
{
    transform: scale(1.05); 
}

.pp-list-vid
{
    width: 800px;
    object-fit: cover;
}

.pp-main-vid
{
    /* width: 1600px;
    object-fit: cover; */
}

.pp-list-img
{
    width: 800px;
    height: 500px;
    object-fit: cover;
}

.pp-list-img2
{
    /* width: 500px; */
    height: 200px;
    object-fit: cover;
}

.pp-sm-list-img
{
    width: 300px;
    /* height: 552px; */
    object-fit: cover;
}

.appearance-manifolds-list-img
{
    /* width: 300px; */
    /* height: 300px; */
    height: 200px;
    /* height: 200px; */
    /* width: 50%; */
    object-fit: contain;
    /* height: 100%; */
}

.source-link
{
    position: relative;
    bottom: 60px;
}

.source-link-text
{
    font-size: 1em;
    color: black;
}

.grid-container-quad
{
    padding: 0px;
    margin: 0px;
}

.grid-item-quad
{
    padding: 0px;
    margin: 0px;
}

.dev-list
{
    border-right: solid #D9D9D9 3px;
}

@media screen and (min-width: 300px)
{
    .pp-grid-container-five-col
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .pp-concept-img
    {
        width: 80vw;
        height: 30vw;
    }

    .pp-main-desc
    {
        width: 80vw;
        font-size: 18px;
    }

    .pp-visit-site
    {
        font-size: 14px;
        white-space: nowrap;
    }

    .pp-list-vid
    {
        /* width: 30vw; */
        min-width: 50px;
        max-width: 170px;
    }

    .pp-list-img
    {
        max-height: 100px;
        min-height: 50px;

        max-width: 170px;
        min-width: 100px;
    }

    .pp-list-img2
    {
        max-height: 80px;
        min-height: 70px;

        /* max-width: 
        min-width:  */
    }

    .pp-sm-list-img
    {
        width: 100px;
    }

    .pp-flexbox-item
    {
        font-size: 14px;
    }

    .pp-grid-item-col-left-align
    {
        padding: 0px;
    }

    .pp-grid-item-col-right-align
    {
        padding: 0px;
    }

    .pp-grid-container
    {
        padding: 0px;
    }

    .pp-grid-container-single-row
    {
        padding: 0px;
    }

    .subtitle-flexbox
    {
        margin-left: 0px;
    }

    .source-link-text
    {
        font-size: 0.5em;
    }

    .pp-title
    {
        font-size: 38px;
    }

    .pp-subtitle
    {
        font-size: 26px;
    }

    .appearance-manifolds-list-img
    {
        height: 0px;
        width: 10px;
    }
}

@media screen and (min-width: 768px)
{
    .pp-grid-container-five-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .pp-concept-img
    {
        width: 80vw;
        height: 400px;
    }

    .pp-main-desc
    {
        width: 80vw;
        font-size: 22px;
    }

    .pp-visit-site
    {
        font-size: 14px;
        white-space: nowrap;
    }

    .pp-list-vid
    {
        /* width: 35vw; */
        min-width: 80px;
        max-width: 400px;
    }

    .pp-main-vid
    {
        /* max-width: 800px; */
        /* max-height: 675px; */

        /* min-width: 600px; */
        /* min-height: 618.75x; */
    }


    .pp-list-img
    {
        /* width: 45vw; */
        max-height: 200px;
        min-height: 100px;

        max-width: 350px;
        min-width: 200px;
    }

    .pp-list-img2
    {
        max-height: 100px;
        min-height: 80px;

        /* max-width: 
        min-width:  */
    }

    .pp-sm-list-img
    {
        width: 200px;
    }

    .pp-flexbox-item
    {
        font-size: 24px;
    }

    .pp-grid-item-col-left-align
    {
        padding: 10px;
    }

    .pp-grid-item-col-right-align
    {
        padding: 10px;
    }

    .pp-grid-container
    {
        padding: 0px;
    }

    .pp-grid-container-single-row
    {
        padding: 0px;
    }

    .subtitle-flexbox
    {
        margin-left: 0px;
    }

    .source-link-text
    {
        font-size: 0.5em;
    }

    .pp-title
    {
        font-size: 48px;
    }

    .pp-subtitle
    {
        font-size: 36px;
    }

    .appearance-manifolds-list-img
    {
        height: 300px;
        width: 500px;
    }
}

@media screen and (min-width: 1200px)
{
    .pp-grid-container-five-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .pp-concept-img
    {
        width: 80vw;
        height: 400px;
    }

    .pp-main-desc
    {
        width: 80vw;
        font-size: 22px;
    }

    .pp-visit-site
    {
        font-size: 14px;
        white-space: nowrap;
    }

    .pp-list-vid
    {
        /* width: 35vw; */
        min-width: 100px;
        max-width: 400px;
    }

    .pp-main-vid
    {
        /* max-width: 1500px;
        max-height: 675px;

        min-width: 1200px;
        min-height: 618.75x; */
    }

    .pp-list-img
    {
        /* width: 45vw; */
        max-height: 200px;
        min-height: 100px;

        max-width: 350px;
        min-width: 200px;
    }

    .pp-list-img2
    {
        max-height: 100px;
        min-height: 80px;

        /* max-width: 
        min-width:  */
    }

    .pp-sm-list-img
    {
        width: 200px;
    }

    .pp-flexbox-item
    {
        font-size: 24px;
    }

    .pp-grid-item-col-left-align
    {
        padding: 10px;
    }

    .pp-grid-item-col-right-align
    {
        padding: 10px;
    }

    .pp-grid-container
    {
        padding: 0px;
    }

    .pp-grid-container-single-row
    {
        padding: 0px;
    }

    .subtitle-flexbox
    {
        margin-left: 0px;
    }

    .source-link-text
    {
        font-size: 0.5em;
    }

    .pp-title
    {
        font-size: 48px;
    }

    .pp-subtitle
    {
        font-size: 36px;
    }

    .appearance-manifolds-list-img
    {
        height: 300px;
        width: 500px;
    }
}

    @media screen and (min-width: 1220px)
    {
    .pp-grid-container-five-col
    {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .pp-concept-img
    {
        min-width: 1120px;
        min-height: 800px;
    }

    .pp-main-desc
    {
        width: 85vw;
        font-size: 24px;
    }

    .pp-visit-site
    {
        font-size: 18px;
        white-space: nowrap;
    }

    .pp-list-vid
    {
        /* width: 800px; */

        max-width: 600px;
        max-height: 300px;

        min-width: 500px;
        min-height: 200px;
    }

    .pp-main-vid
    {
        /* max-width: 1600px;
        max-height: 900px;

        min-width: 1500px;
        min-height: 843.75px; */

        /* min-width: 1120px;
        min-height: 800px; */
    }

    .pp-list-img
    {
        max-width: 600px;
        max-height: 300px;

        min-width: 500px;
        min-height: 200px;
    }

    .pp-list-img2
    {
        max-height: 200px;
        min-height: 100px;

        /* max-width: 
        min-width:  */
    }

    .pp-sm-list-img
    {
        width: 300px;
    }

    .pp-flexbox-item
    {
        font-size: 24px;
    }

    .pp-grid-item-col-left-align
    {
        padding: 20px;
    }

    .pp-grid-item-col-right-align
    {
        padding: 20px;
    }

    .pp-grid-container
    {
        padding: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }

    .pp-grid-container-single-row
    {
        padding: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }

    .subtitle-flexbox
    {
        margin-left: 72px;
    }

    .source-link-text
    {
        font-size: 0.5em;
    }

    .pp-title
    {
        font-size: 48px;
    }

    .pp-title
    {
        font-size: 48px;
    }

    .pp-subtitle
    {
        font-size: 36px;
    }

    .appearance-manifolds-list-img
    {
        height: 200px;
    }
}