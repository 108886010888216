.n
{
    background-color: #F3F3F3;
}

.n-grid-container
{
    display: grid;
    grid-template-columns: auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.n-grid-item-col {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
  .n-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Put items at start and end of flex container/grid cell */
    justify-content:space-between;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 5px;
  }

  .n-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333; */
    /* background-color: #dfdfdf; */
    /* align-self: flex-end; */
}



.n-flexbox-container
{
     /* Initiallizes flexbox */
     display: flex;
     /* Fits and resizes everything into its respective box */
     min-width: min-content;
     /* justify-content: center; */
     /* align-items: center; */
     /* Center items vertically */
     /* align-items: center;  */
     /* Rearranges items on page instead of just resizing them */
     /* flex-wrap: wrap; */
     /* Sets up flex boxes to be arranged in columns (top to bottom) */
     flex-direction: row;
     /* Prevents overflow of text and items on page */
     width: 100%;
     justify-content: space-between;
}

.n-logo
{
    /* width: 51px;
    height: 86px; */
    width: 76px;
    height: 48.33px;
    /* width: 90px;
    height: 51.75px; */
    fill: black;
    background-image: none;
    padding: 10px;
    transition: all .2s ease-in-out;

}

.n-logo:hover
{
    /* background-image: url("./svg/HomeIconSquiggle2.svg"); */
    opacity: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: show;
}

.n-right-links
{
    /* Center on vertical axis */
    align-self: center;

    /* Font formatting */
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    /* line-height: 120%; */

    text-align: center;
    letter-spacing: -0.03em;
    color: #181717;
}

.work-link
{
    padding-left: 15px;
    padding-right: 15px;
    display: inline;
    color: #181717;
    /* remove underline */
    text-decoration-line: none;
    /* header circle */
    background-image: none;
}

.work-link:hover
{
    background-image: show;
    background-image:url("./svg/HeaderLine1.svg");
    background-size: 100% 100%;
}

.about-link
{
    padding-left: 15px;
    padding-right: 15px;
    display: inline;
    color: #181717;
    /* remove underline */
    text-decoration-line: none;

    /* header circle */
    background-image: none;
    
}

.about-link:hover
{
    background-image: show;
    background-image:url("./svg/HeaderLine2.svg");
    background-size: 100% 100%;
}

.contact-link
{
    padding-left: 15px;
    padding-right: 15px;
    display: inline;
    color: #181717;
    /* remove underline */
    text-decoration-line: none;
    /* header circle */
    background-image: none;
}

.contact-link:hover
{
    background-image: show;
    background-image:url("./svg/HeaderLine3.svg");
    background-size: 100% 100%;
}

.mode-switch
{
    translate: 0 4px;
    border: solid black;
    display: inline;
    width: 26px;
    height: 25px;
    cursor: pointer;
    padding: 2px;
    background-image: none;    
}

.mode-switch:hover
{
    
    background-image: url("./svg/nav-circle2.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: show;
}

/* mode switch light */
.light
{
    display: none;
    width: 24px;
    height: 25px;
}

/* mode switch dark */
.dark
{
    display: inline;
}

/* Change color of specific paths in an svg */
/* Be sure to name these classNames in the .svg file before setting colors */
.night-toggle-icon-path1
{
    /* fill: "#C4C4C4"; */
    fill: none;
    /* stroke: "#C4C4C4"; */
    stroke: black;
}

.night-toggle-icon-path2
{
    /* stroke: "#C4C4C4"; */
    stroke: black;
    /* fill: "#C4C4C4"; */
    fill: black;
}



/* Prevents horizontal scrolling */
html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.n-header-circle1
{
    position: relative;
    /* top: 10px; 
    left: 258px; */
    right: 120px;
    width: 79.12px;
    height: 42.03px;
}

.n-header-circle2
{
    position: relative;
    top: 6px; 
    left: 260px;
    width: 84.56px;
    height: 32.45px;
}

.n-header-circle3
{
    position: relative;
    top: 6px; 
    left: 264px;
    width: 101.52px;
    height: 29.02px;    
}

@media screen and (min-width: 300px)
{
    .n-grid-container 
    {
        padding: 0px;
    }

    .n-grid-container-single-row
    {
        padding: 0px;
    }
}

@media screen and (min-width: 768px)
{
    .n-grid-container 
    {
        padding: 0px;
    }

    .n-grid-container-single-row 
    {
        padding: 0px;
    }
}

@media screen and (min-width: 1200px)
{
    .n-grid-container 
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }

    .n-grid-container-single-row 
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }
}