.pg
{
  background-color: #F3F3F3;
}

/* Holds an entire grid of 3 columns*/
.pg-grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* Holds an entire grid of 2 columns*/
.pg-grid-container-two-col {
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  /* background-color: #2196F3; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 72px;
  padding-right: 72px;
}

  /* Holds the entire grid of one row */
.pg-grid-container-single-row {
    display: grid;
    grid-template-rows: auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
  .pg-grid-item-col {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 0px;
    font-size: 30px;
  }

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
  .pg-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  .pg-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333; */
    /* background-color: #dfdfdf; */
}

.pg-grid-item-space {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    height: 100px;
  }

.pg-grid-item-col-left-align 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
}

.pg-grid-item-col-left-align-proj-title
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    height: 12px;
}

.pg-grid-item-col-proj-img {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    background-color: white;
  }

.pg-title
{
    width: 159px;
    height: 90px;

    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 90px;
    /* identical to box height */
    display: flex;
    align-items: center;
    color: #181717;
}

.pg-desc
{
    font-style: normal;
    font-weight: 100;
    font-size: 36px;
    line-height: 54px;
    display: flex;
    align-items: center;
    color: #181717;
}

.pg-prof-work-title
{
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #181717;
}

.pg-proj-img
{
    width: 420px;
    height: 238px;
    object-fit: cover;
}

.pg-proj-title
{
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 27px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #181717;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 372px;
}

.pg-coming-soon-text
{
    font-family: "Assistant";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    padding: 40px;
    /* transform:rotate(-4deg); */

    background-image: url("../../svg/ComingSoonUnderline2.svg");
    background-repeat: no-repeat;
    background-size: 80% 80%;
    /* background-size: 212.35px 28.02px; */
    background-position: 40px 40px;
    text-align: center;
}

.pg-page-break
{
    width: 1439px;
    height: 5px;
}

.pg-proj-link
{
  text-decoration-line: none;
  transition: all .2s ease-in-out;
}

.pg-proj-link:hover
{
  transform: scale(1.05); 
}

.pg-project-overlay
{
  text-align: center;
  position: absolute;
  background-color: white;
  opacity: 0%;
  border: none;
  width: 420px;
  /* height: 238px; */
  transition: all .2s ease-in-out;
  /* display: none; */
  pointer-events: none;
}

.pg-proj-link:hover ~ #project-overlay
{
  transform: scale(1.05); 
  display: block;
  opacity: 80%;

}

.pg-overlay-text
{
  font-size: 18px;
  padding-bottom: 10px;
}

.pg-overlay-title
{
  font-family: "Assistant";
  font-size: 18px;
  font-weight: 500;
}

.pg-grid-container-three-col {
  display: grid;
  /* Auto spacing changes column width based on size of the content */
  /* grid-template-columns: auto auto auto auto auto; */
  /* Equal column width for all 5 columns */
  /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
  /* Shorthand method for evenly-spacing the 5 columns */
  grid-template-columns: repeat(1, minmax(0, 1fr));
  /* Align each a-flexbox-item by the top */
  align-items:flex-start;
  width: 100%;
}

@media screen and (min-width: 300px)
{
  .pg-grid-container
  {
    grid-template-columns: auto;
  }

  .pg-grid-container-two-col
  {
    grid-template-columns: auto;
  }

  .pg-page-break
  {
      width: 60vw;
      height: 5px;
  }

  .pg-coming-soon-text
  {
    background-size: 60% 80%;
    background-position: 80px 70px;
  }

  .pg-proj-img
  {
      width: 315px;
      height: 178.5px;
      object-fit: cover;
  }

  .pg-project-overlay
  {
    width: 315px;
  }

  .pg-overlay-text
  {
    font-size: 14px;
    font-weight: 200;
  }

  .pg-grid-container-single-row
  {
    padding: 0px;
  }

  .pg-grid-container
  {
    padding: 0px;
  }
}

@media screen and (min-width: 768px)
{
  .pg-grid-container
  {
    grid-template-columns: auto;
  }

  .pg-grid-container-two-col
  {
    grid-template-columns: auto;
  }

  .pg-page-break
  {
      width: 20ex;
      height: 5px;
  }

  .pg-coming-soon-text
  {
    background-size: 80% 80%;
    background-position: 40px 40px;
  }

  .pg-proj-img
  {
      width: 420px;
      height: 238px;
      object-fit: cover;
  }

  .pg-project-overlay
  {
    width: 420px;
  }

  .pg-overlay-text
  {
    font-size: 16px;
  }

  .pg-grid-container-single-row
  {
    padding: 0px;
  }

  .pg-grid-container
  {
    padding: 0px;
  }

  .pg-grid-container-two-col {
    display: grid;
    /* grid-template-columns: auto auto; */
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .pg-grid-container-three-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    /* width: 100%; */
  }

}

@media screen and (min-width: 1200px)
{
  .pg-grid-container
  {
    grid-template-columns: auto;
  }

  .pg-grid-container-two-col
  {
    grid-template-columns: auto;
  }

  .pg-page-break
  {
      width: 40ex;
      height: 5px;
  }

  .pg-coming-soon-text
  {
    background-size: 80% 80%;
    background-position: 40px 40px;
  }

  .pg-proj-img
  {
      width: 420px;
      height: 238px;
      object-fit: cover;
  }

  .pg-project-overlay
  {
    width: 420px;
  }

  .pg-overlay-text
  {
    font-size: 18px;
  }

  .pg-grid-container-single-row
  {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .pg-grid-container
  {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .pg-grid-container-two-col {
    display: grid;
    /* grid-template-columns: auto auto; */
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .pg-grid-container-three-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    /* width: 100%; */
  }
}

@media screen and (min-width: 1220px)
{
  .pg-grid-container
  {
    grid-template-columns: auto auto auto;
  }

  .pg-grid-container-two-col
  {
    grid-template-columns: auto auto;
  }

  .pg-page-break
  {
      width: 100ex;
      height: 5px;
  }

  .pg-coming-soon-text
  {
    background-size: 80% 80%;
    background-position: 40px 40px;
  }

  .pg-proj-img
  {
      width: 420px;
      height: 238px;
      object-fit: cover;
  }

  .pg-project-overlay
  {
    width: 420px;
  }

  .pg-overlay-text
  {
    font-size: 18px;
  }

  .pg-grid-container-single-row
  {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .pg-grid-container
  {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .pg-grid-container-two-col {
    display: grid;
    /* grid-template-columns: auto auto; */
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .pg-grid-container-three-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(3, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    width: 100%;
  }
}