.ppg-grid-container 
{
    display: grid;
    grid-template-rows: auto;
    padding: 10px;
    /* border: solid red; */
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.ppg-grid-item-row 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    padding: 20px;
    font-size: 30px;
    /* border: solid green; */
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in rows */
.ppg-grid-item-col 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    padding: 20px;
    font-size: 30px;
    /* border: solid purple; */
}

.ppg-title
{
    font-weight: 300;
    font-size: 80px;
}

.ppg-subtitle
{
    font-weight: 100;
    font-size: 45px;
}

.ppg-paragraph
{
    font-weight: 100;
    font-size: 20px;
}

.ppg-tools-text
{
    /* font-size: 16px; */
    text-align: center;
    padding: 0px;
    width: 200px;
    font-weight: 100;
    background-repeat: no-repeat;
    background-position: center;
    white-space: nowrap;
    margin: 10px;
    /* display: flex;  */
    /* align-items: center; */
    /* text-align: center; */
}

.ppg-sb1
{
    background-image: url("../../svg/SketchyButton1.svg");
}
.ppg-sb2
{
    background-image: url("../../svg/SketchyButton2.svg");
}
.ppg-sb3
{
    background-image: url("../../svg/SketchyButton4.svg");
}
.ppg-sb5
{
    background-image: url("../../svg/SketchyButton5.svg");
}
.ppg-sb6
{
    background-image: url("../../svg/SketchyButton6.svg");
}
.ppg-sb7
{
    background-image: url("../../svg/SketchyButton7.svg");
}
.ppg-sb8
{
    background-image: url("../../svg/SketchyButton8.svg");
}

.ppg-sb9
{
    background-image: url("../../svg/SketchyButton9.svg");
}

.ppg-sb10
{
    background-image: url("../../svg/SketchyButton10.svg");
}

.ppg-sb11
{
    background-image: url("../../svg/SketchyButton11.svg");
}

.ppg-sb12
{
    background-image: url("../../svg/SketchyButton12.svg");
}

.ppg-sb13
{
    background-image: url("../../svg/SketchyButton13.svg");
}

.ppg-sb14
{
    background-image: url("../../svg/SketchyButton14.svg");
}

.ppg-sb15
{
    background-image: url("../../svg/SketchyButton15.svg");
}

.ppg-sb16
{
    background-image: url("../../svg/SketchyButton16.svg");
}

.ppg-grid-container-five-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(5, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    width: 100%;
}

.ppg-grid-container-four-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(4, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    width: 100%;
}

.ppg-grid-container-three-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(3, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    width: 100%;
}

.ppg-grid-container-two-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    width: 100%;
}

.ppg-sketchy-box-text-flexbox
{
  
    /* background-color: blue; */
    background-repeat: no-repeat;
    background-position:top right;
    white-space: nowrap;
    background-size: 100% 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    /* line-height: 27px; */
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.ppg-button
{
    font-family: 'Assistant', sans-serif;
    font-size: 15px;
    font-weight: 200;
    width: 120px;
}

/* Media Queries */
@media screen and (min-width: 300px)
{
    .ppg-grid-container-five-col
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .ppg-grid-container-four-col
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .ppg-grid-container-three-col
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .ppg-grid-container-two-col
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (min-width: 768px)
{
    .ppg-grid-container-five-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .ppg-grid-container-four-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .ppg-grid-container-three-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .ppg-grid-container-two-col
    {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (min-width: 1200px)
{
    .ppg-grid-container-five-col
    {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .ppg-grid-container-four-col
    {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .ppg-grid-container-three-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .ppg-grid-container-two-col
    {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}