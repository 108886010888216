.a
{
    background-color: #F3F3F3;
}

/* Holds the entire grid -  3 columns */
.a-grid-container {
    display: grid;
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* Holds the entire grid -  5 columns */
.a-grid-container-five-col {
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* grid-template-columns: auto auto auto auto auto; */
    /* Equal column width for all 5 columns */
    /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(5, minmax(0, 1fr));
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
    /* width: 75%; */
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;

    /* background-color: red; */
    
    width: 90vw;
    /* flex-wrap: wrap; */
}

/* Holds the entire grid of one row */
.a-grid-container-single-row {
    display: grid;
    grid-template-rows: auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* Holds the entire grid of one row */
.a-grid-container-single-col {
    display: grid;
    grid-template-columns: auto;
    /* background-color: #2196F3; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 72px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
  .a-grid-item-col {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
  }

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
  .a-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    flex-wrap: wrap;
  }


.a-grid-item-space {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    height: 200px;
  }

.a-grid-item-col-left-align {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    /* background-color: blue;
    border: solid white; */
  }

  .a-flexbox-item
  {
       /* Fits and resizes everything into its respective box */
      min-width: min-content;
  
      /* Styling */
      margin: 10px;
      /* border: 3px solid #333; */
      /* background-color: #dfdfdf; */
  }

  .a-title
  {
      width: 294px;
      height: 90px;
    
      font-style: normal;
      font-weight: 300;
      font-size: 60px;
      line-height: 90px;
      /* identical to box height */
  
      display: flex;
      align-items: center;
      color: #181717;
  }

.a-sub
{
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 54px;
    display: flex;
    align-items: center;
    color: #181717; 
}

.a-avatar
{
    height: 300px;
    width: 300px;
}

.a-desc
{
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;

    display: flex;
    align-items: center;
    color: #181717;
}

.resume-link
{
    height: 43px;

    font-family: 'Assistant';
    font-style: normal;
    font-weight: 100;
    font-size: 24px;
    line-height: 43px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-decoration-line: none;
    color: #FF5E5B;

    background-image: url("../../svg/link-underline.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 15px;
    transition: all .2s ease-in-out;
}

.resume-link:hover
{
    transform: scale(1.05); 
}

.a-page-break
{
    /* width: 1439px; */
    width: 40em;
    height: 5px;
}

.a-sketchy-box-text-holder
{
    white-space: nowrap;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;

    /* background-color: green; */
}

.sb1
{
    background-image: url("../../svg/SketchyButton1.svg");
}

.sb2
{
    background-image: url("../../svg/SketchyButton2.svg");
}

.sb3
{
    background-image: url("../../svg/SketchyButton3.svg");
}

.sb4
{
    background-image: url("../../svg/SketchyButton4.svg");
}

.sb5
{
    background-image: url("../../svg/SketchyButton5.svg");
}
.sb6
{
    background-image: url("../../svg/SketchyButton6.svg");
}

.sb7
{
    background-image: url("../../svg/SketchyButton7.svg");
}

.sb8
{
    background-image: url("../../svg/SketchyButton8.svg");
}

.a-sketchy-box-text
{
    font-style: normal;
    display: flex;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    justify-content: center;
    text-align: center;

    /* Keeps boxes at equal width */
    width: 100px;
    max-width: 100px;
}

@media screen and (min-width: 300px)
{
    .a-grid-container-five-col
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .a-grid-container
    {
        grid-template-columns: auto;
    }

    .a-page-break
    {
        width: 40em;
        height: 5px;
    }

    .tech-software
    {
        width: 70%;
    }
}

@media screen and (min-width: 300px)
{
    .a-grid-container-five-col
    {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .a-grid-container
    {
        grid-template-columns: auto;
    }

    .a-page-break
    {
        width: 40em;
        height: 5px;
    }

    .a-sketchy-box-text
    {
        /* Keeps boxes at equal width */
        width: 100px;
        max-width: 100px;
    }

    .a-grid-container
    {
        padding: 0px;
    }
    .a-grid-container-single-row
    {
        padding: 0px;
    }
    .a-grid-container-five-col
    {
        padding: 0px;
    }

    .a-sketchy-box-text-holder
    {
        padding-left: 20px;
        padding-right: 20px;
    }

    .tech-software
    {
        width: 80%;
    }
}

@media screen and (min-width: 768px)
{
    .a-grid-container-five-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .a-grid-container
    {
        grid-template-columns: auto auto;
    }

    .a-page-break
    {
        width: 40em;
        height: 5px;
    }

    .a-sketchy-box-text
    {
        /* Keeps boxes at equal width */
        width: 80px;
        max-width: 80px;
    }

    .a-grid-container
    {
        padding: 0px;
    }
    .a-grid-container-single-row
    {
        padding: 0px;
    }
    .a-grid-container-five-col
    {
        padding: 0px;
    }

    .a-sketchy-box-text-holder
    {
        padding-left: 50px;
        padding-right: 50px;
    }

    .tech-software
    {
        width: 90%;
    }
}

@media screen and (min-width: 1200px)
{
    .a-grid-container-five-col
    {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .a-grid-container
    {
        grid-template-columns: auto auto;
    }

    .a-page-break
    {
        width: 40em;
        height: 5px;
    }

    .a-sketchy-box-text
    {
        /* Keeps boxes at equal width */
        width: 30px;
        max-width: 100px;
    }

    .a-grid-container
    {
        padding: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }
    .a-grid-container-single-row
    {
        padding: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }
    .a-grid-container-five-col
    {
        padding: 10px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .a-sketchy-box-text-holder
    {
        padding-left: 50px;
        padding-right: 50px;
    }

    .tech-software
    {
        width: 100%;
    }
}